<template>
  <div class="program-container">
    <!-- Hero -->
    <hero :content="hero" />

    <!-- Section Info -->
    <section
      v-if="data.current_page"
      class="container my-4"
    >
      <b-row cols="3">
        <b-col
          v-for="(promo, i) in promos"
          :key="i"
        >
          <course-card :promo="promo" />
        </b-col>
      </b-row>
      <div
        v-if="data.current_page < data.last_page"
        class="d-flex justify-content-center"
      >
        <b-button
          variant="outline-primary"
          @click="loadMore"
        >
          <i class="ti ti-arrow-down" />
          Lihat Selanjutnya
        </b-button>
      </div>
    </section>

    <!-- Explore -->
    <explore />

    <!-- Footer -->
    <custom-footer />
  </div>
</template>

<script>
import { BButton, BRow, BCol } from 'bootstrap-vue'

// Custom Components
import Explore from '@/views/tekov/landingpage/components/Explore.vue'
import CustomFooter from '@/views/tekov/landingpage/components/Footer.vue'
import Hero from '@/views/tekov/components/Hero.vue'
import { reqGetPromos } from '@/api/guest/promo'
import CourseCard from './components/CourseCard.vue'

export default {
  components: {
    BRow,
    BCol,
    BButton,

    Hero,
    CourseCard,

    Explore,
    CustomFooter,
  },
  data() {
    return {
      hero: {
        title: 'Promo dan Diskon',
        desc:
          'Dapatkan promo diskon untuk berlangganan Pelatihan di TekOv. Yuk, cek detail promonya!',
      },

      // Promos
      limit: 3,
      data: {},
      filters: {
        per_page: 3,
        page: 1,
      },
      promos: [],
    }
  },
  computed: {
  },
  mounted() {
    this.loadPromo()
  },
  methods: {
    loadPromo() {
      reqGetPromos(this.filters).then(res => {
        this.data = res.data.data
        this.promos = [...this.promos, ...this.data.data.map(promo => ({
          title: promo.title,
          desc: promo.description,
          image: promo.image,
          code: promo.code,
          duration_start: promo.start_at,
          duration_end: promo.end_at,
        }))]
      })
    },
    loadMore() {
      this.filters.page = this.data.current_page + 1
      this.loadPromo()
    },
  },
}
</script>

<style lang="scss">
.program-container {
  position: absolute;
  width: 100%;
  left: 0;
  right: 0;
  top: 100px !important;
}

.list-padding {
  padding: 0.4rem 0;
}

.offset-mt {
  margin-top: -18rem;
}

.clean-paragraph {
  text-justify: inter-word;
  line-height: 26px;
}
</style>
