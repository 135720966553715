<template>
  <b-card img-alt="Card image cap" img-top class="p-0" no-body>
    <b-card-img :src="promo.image" class="card-image-size" />
    <b-card-body>
      <b-badge pill variant="light-primary">
        {{ formatDurationDate(promo.duration_start, promo.duration_end) }}
      </b-badge>
      <h4 class="font-weight-bolder py-1">
        {{ promo.title }}
      </h4>

      <p>{{ promo.desc }}</p>

      <span class="font-weight-bolder text-primary">
        <i class="text-secondary ti ti-receipt-tax" />
        Kode Promo : {{ promo.code }}
      </span>

      <div class="d-flex justify-content-center mt-1">
        <b-button v-ripple.400="'rgba(113, 102, 240, 0.15)'" class="w-100" variant="secondary"
          @click="useCode(promo.code)">
          <i class="ti ti-tag" />
          Gunakan Promo
        </b-button>
      </div>
    </b-card-body>
  </b-card>
</template>

<script>
import { BCard, BCardImg, BCardBody, BButton, BBadge } from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  components: {
    BCard,
    BCardImg,
    BCardBody,
    BButton,
    BBadge,
  },
  directives: {
    Ripple,
  },
  props: {
    promo: {
      type: Object,
      default: () => ({
        title: 'Diskon 75%',
        desc:
          'Promo Diskon hingga 75% dengan Kartu Kredit BCA dan Debit BCA Mastercard.',
        image: require('@/assets/images/slider/06.jpg'),
        code: 'TEKOV2123',
        duration_start: '2020-01-01',
        duration_end: '2020-02-02',
      }),
    },
  },
  methods: {
    formatDate(date) {
      return new Date(date).toLocaleDateString('id-ID', {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
      })
    },
    formatDurationDate(start, end) {
      start = this.formatDate(start)
      end = this.formatDate(end)

      if (start === end) {
        return start
      }
      // check if month is same
      if (start.split(' ')[1] === end.split(' ')[1]) {
        return `${start.split(' ')[0]} - ${end}`
      }
      // check if year is same
      if (start.split(' ')[2] === end.split(' ')[2]) {
        return `${start.split(' ')[0]} ${start.split(' ')[1]} - ${end}`
      }
      return `${start} - ${end}`
    },
    useCode(code) {
      navigator.clipboard.writeText(code);
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Berhasil',
          icon: 'CheckIcon',
          text: 'Kode promo berhasil di Copy',
          variant: 'success',
        },
      });
    }
  },
}
</script>

<style lang="scss" scoped>
$secondary: #fb6514;

.ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

.price {
  width: 100%;
  font-size: 1.5rem;
  font-weight: 600;
  color: $secondary;
}

.price-strike {
  width: 100%;
  font-size: 1rem;
  font-weight: 400;
  text-decoration: line-through;
}

.bg-secondary {
  background-color: $secondary !important;
}

.border-secondary {
  border-color: $secondary !important;
}

.card-img-top {
  object-fit: cover;
  height: 250px;
}

.card-image-size {
  aspect-ratio: 16 / 9;
}
</style>
